import React, { useState, useEffect, useCallback } from "react";
import GoogleAutoComplete from "../../../common/googleAutocomplete/googleAutocomplete";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import InputField from "../../../registration/Inputs/InputField";
import SelectField from "../../../registration/Inputs/SelectField";
import InputDateField from "../../../registration/Inputs/InputDateField";
import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
import InputTextArea from "../../../registration/Inputs/InputTextArea";
import PropertyType from "./property-type/property-type";
import { useGetMasterRegistrationDataQuery } from "../../../../services/registrationApi";
import { useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import InputMaskField from "../../../registration/Inputs/InputMaskFieid";
import InputFieldArrayLandlord from "../personal-details/InputFieldArrayLandlord";
import InputFieldPhone from "../../../registration/Inputs/InputFieldPhone";
import { AiOutlineCheckCircle } from "react-icons/ai";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import InputCheckBoxField from "../../../registration/Inputs/InputCheckBoxField";

const Address = (props) => {
  const navigate = useNavigate();
  let nextDate = new Date().setDate(new Date().getDate() + 1);
  const { register, watch, getValues, control, setValue } = useFormContext();
  const [isCurrentAddress, setIsCurrentAddress] = useState(
    register("identification_data.is_current_address")
  );
  const [open, setOpen] = useState(false);
  const [deleteAddressIndex, setDeleteAddressIndex] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "address",
    keyName: "fields_id",
  });
  const { dirtyFields, errors, touchedFields } = useFormState({
    control,
  });
  const error = errors["propertyType"];

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const { data, isLoading, isError, isSuccess } =
    useGetMasterRegistrationDataQuery({ trn_id, url });
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      debouncedSave(name, false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [nextSection, setNextSection] = useState(false);
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const debouncedSave = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(await props.checkValidation("Address", false));
        })(),
      1000
    ),
    [] // will be created only once initially
  );

  return (
    <AccordionDetails>
      {fields.map((field, index) => {
        let newIndex = index + 1;
        return (
          <div key={field.fields_id} id="address">
            <div
              className={`${useWindowWidth.windowWidth < 766
                  ? "display-none xs-title-acc"
                  : "xs-title-acc"
                }`}
            >
              <h3 className="">Address</h3>
            </div>
            <div
              // id="addressCollapse"
              className={`${useWindowWidth.windowWidth < 766
                  ? "  p-d-f-wrap white-box"
                  : "p-d-f-wrap white-box"
                }`}
            >
              <div className="a-blue-box">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <span className="s-no-index float-left">{newIndex}</span>{" "}
                    {newIndex == 1 ? (
                      <span className="address-text">Current Address</span>
                    ) : (
                      <></>
                    )}
                    {newIndex !== 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          setDeleteAddressIndex(index);
                          setOpen(true);
                        }}
                        title="Delete this Employer"
                        className="float-right btn btn-sm btn-danger"
                      >
                        <TiDelete size={25} /> Delete
                      </button>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group cus-input-group mr-b30">
                      <GoogleAutoComplete
                        notRequired={true}
                        groupFieldName={{
                          street: `address.${index}.street_address`,
                          city: `address.${index}.city`,
                          state: `address.${index}.state`,
                          zip: `address.${index}.zipcode`,
                          state_data: data?.records?.States,
                        }}
                        register={register(`address.${index}.addressSearch`)}
                        label={index > 0 ? "Search Previous Address" : "Search Current Address"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <InputField
                        notRequired={false}
                        label="Street Address"
                        register={register(`address.${index}.street_address`)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <InputField
                        notRequired={true}
                        label="Apartment"
                        register={register(`address.${index}.appartment`)}
                      />
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <InputField
                        label="City"
                        register={register(`address.${index}.city`)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <SelectField
                        options={data ? data?.records?.States : []}
                        label="State"
                        register={register(`address.${index}.state`)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <InputField
                        type={"tel"}
                        maxLength={9}
                        minLength={5}
                        label="Zip Code"
                        register={register(`address.${index}.zipcode`)}
                      />

                      {/* <InputMaskField
                          maxLength={5}
                          inputType="ZIP"
                          label="Zip Code"
                          register={register(`address.${index}.zipcode`)}
                          mask={"99999"}
                        /> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <div className="cus-check mr-b15">
                      {/* <input
                        type="checkbox"
                        id={fields.field}
                        name={'name-1'}
                        // checked={newIndex === 1 ? true : false}
                        {...register(`address.${index}.is_current_address`)}
                      />

                      <label
                        id="name-1"
                        className="mr-b0 mx-auto d-flex align-items-center"
                      >
                        <span className="mr-r10"></span>
                        Is this current address?
                      </label> */}
                      <InputCheckBoxField
                        notRequired={true}
                        disabled={index == 0 ? true : false}
                        checked={
                          index == 0
                            ? true
                            : watch(`address.${index}.is_current_address`)
                        }
                        label="Is this your current address?"
                        register={register(
                          `address.${index}.is_current_address`
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b30">
                      <InputDateField
                        maxDate={new Date()}
                        type="date"
                        label="When did you move in to this address?"
                        placeholder="Enter Date"
                        register={register(`address.${index}.move_in_date`)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4">
                    {watch(`address.${index}.is_current_address`) == false && (
                      <div className="form-group cus-input-group mr-b30">
                        <InputDateField
                          maxDate={new Date()}
                          label="When did you move out to this address?"
                          placeholder="Enter Date"
                          register={register(`address.${index}.move_out_date`)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <PropertyType
                      register={register(`address.${index}.property_type`)}
                      availableValue={watch(`address.${index}.property_type`)}
                    />
                  </div>
                  {watch(`address.${index}.property_type`) == "1" &&
                    watch(`address.${index}.property_type`) !== "3" && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            <InputCurrencyField
                              label="Current Rent"
                              suffix="$"
                              register={register(
                                `address.${index}.current_rent`
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            <InputField
                              label="Landlord Name"
                              register={register(
                                `address.${index}.landlord_name`
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            {/* <InputField
                          type={"tel"}
                          label="Landlord Number"
                          register={register(
                            `address.${index}.landlord_number`
                          )}
                        /> */}
                            <InputFieldPhone
                              label={`Landlord Number`}
                              register={register(
                                `address.${index}.landlord_number`
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  {watch(`address.${index}.property_type`) == "2" &&
                    watch(`address.${index}.property_type`) !== "3" && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            <InputCurrencyField
                              label="Mortgage"
                              suffix="$"
                              register={register(
                                `address.${index}.mortgage_rent`
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="form-group cus-input-group mr-b30">
                            <InputField
                              label="Mortgage Company"
                              register={register(
                                `address.${index}.mortgage_company`
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group cus-input-group mr-b30">
                      <InputTextArea
                        label="Reason for moving"
                        register={register(`address.${index}.reason`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="d-flex justify-content-center">
        <small
          style={{
            color: "#2dc3e8",
          }}
        >
          Please include address history covering two most recent years.
        </small>
      </div>
      <div
        className={`${useWindowWidth.windowWidth < 766
            ? "add-text-btn flex1 text-center ps-2 pt-3"
            : "add-text-btn flex1 text-center ps-2 pt-3 "
          }`}
      >
        <button
          className="mb-4"
          type="button"
          onClick={(event) =>
            append({
              id: 0,
              ID: 0,
              city: "",
              state: "",
              zipCode: "",
              movedDate: "",
              propertyType: "",
              rent: "",
              landLordName: "",
              landLordNumber: "",
              reasonForMoving: "",
              move_in_date: null,
              move_out_date: null,
              is_current_address: false,
            })
          }
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
              fill="#2DC3E8"
            />
          </svg>
          &nbsp; Add current or previous address
        </button>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete address ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              setTimeout(() => {
                remove(deleteAddressIndex);
              }, 200);
              setOpen(false);

              setOpen(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {viewOnlyFlag || nextSection && (
        <Button
          onClick={() => {
            navigate("#ssn");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab{" "}
        </Button>
      )}
    </AccordionDetails>
  );
};

export default Address;
